import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

import RadioList from "../sections/trainingCMS/RadioList";
import CTA from "../sections/trainingCMS/CTA";

interface ICaseStudyProp {
  data: {
    radios: {
      nodes: {
        frontmatter: {
          category: string;
          slug: string;
          title: string;
          previewImageAlt: string;
          previewImage: {
            childImageSharp: {
              fluid: {
                src: string;
              };
            };
          };
        };
      };
    };
  };
}

const CaseStudy: React.FC<ICaseStudyProp> = ({ data }) => {
  const training = data.radios.nodes;

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>ShopWatch Radio Training</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="10">
                <Title variant="hero">Radio Training</Title>
                <Text>
                  Select your radio to start you training or download resources.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <RadioList data={training} />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudy;

export const query = graphql`
  query RadioListPage {
    radios: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "radio-training" } } }
    ) {
      nodes {
        frontmatter {
          slug
          type
          title
          previewImageAlt
          previewImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
